import React from "react";
import { Page } from "react-facebook";
import { ptToVwConverter } from "../../../../utils";
import {
  SNSOdl,
  NameOdl,
  TitleOdl as TitleButton,
  TopSNSContainer,
} from "../../../style";
import { socialMedia } from "../../../../config";
import useWindowSize from "../../../../utils/useWindowSize";

export default () => {
  const link = socialMedia[1].link;
  const size = useWindowSize();
  // FIXME: Resize Facebook with border line
  // Maybe hack react-facebook?

  if (!size) return <p></p>;

  return (
    <TopSNSContainer>
      <TitleButton onClick={() => window.open(link, "_blank")}>
        <SNSOdl src="/assets/facebookLogoColor.png" alt="facebook" />
        <NameOdl>Facebook</NameOdl>
      </TitleButton>

      <div
        style={{
          // height: `${ptToVwConverter(670)}vw`,
          // border: `solid ${ptToVwConverter(1)}vw #d4d4d4`,
          // FIXME: Just for now!
          textAlign: "center",
        }}
      >
        <Page
          href="https://www.facebook.com/aokigwp2018/"
          tabs="timeline"
          target="_top"
          style={
            size === "desktop"
              ? {
                  width: `${ptToVwConverter(360 - 3)}vw`,
                  height: `${ptToVwConverter(670 - 2)}vw`,
                }
              : {
                  width: "100%",
                }
          }
        />
      </div>
    </TopSNSContainer>
  );
};
