import { Grid } from "@material-ui/core";

import PreCSS from "../src/utils/preRenderCSS";
import Video from "../src/components/Video";
import Upcoming from "../src/components/Top/Upcoming";
import News from "../src/components/Top/News";
import About from "../src/components/Top/About";
import Main from "../src/components/Top/Main";

import { ptToVwConverter } from "../src/utils";

import axios from "axios";
import i18nNext, { useTranslation } from "../i18n";
import { BlackContainer } from "../src/components/style";
import Head from "next/head";
import useWindowSize from "../src/utils/useWindowSize";

interface Props {
  // TODO: Fixme later
  news: News[];
  upcoming: News[];
  performances: PerformanceObject[];
  workshops: Workshop[];
  instaMediaUrls: [{ link: string; src: string }];
  lang: string;
  about: string;
}

function Top(props: Props) {
  const {
    news,
    upcoming,
    performances,
    workshops,
    instaMediaUrls,
    lang,
  } = props;

  if (!i18nNext.i18n.languages) {
    i18nNext.i18n.languages = ["ja", "en"];
  }

  const [t, _] = useTranslation("top");

  const windowSize = useWindowSize();

  const spacing = 50;
  const photoWidth = 250;
  const maxWidth = spacing + photoWidth;

  return (
    <>
      <Head>
        <title>
          zerO
          HP（青木尚哉が主催するコンテンポラリーダンスグループ、ゼロの公式ホームページ）
        </title>
        <meta
          property="og:title"
          content="zerO
          HP（青木尚哉が主催するコンテンポラリーダンスグループ、ゼロの公式ホームページ）"
        />
        <meta
          property="og:description"
          content="青木尚哉が主催するコンテンポラリーダンスグループzerO（ゼロ）の公式ホームページです。公演やワークショップ等の情報を掲載しています。This is the official website of zerO, the contemporary dance group organized by Naoya Aoki. You can find information about performances and workshops."
        />
        <meta property="og:image" content="/assets/logoShare.jpg" />
        <meta property="og:url" content="https://zero-dance.com/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Head>
      <PreCSS />
      <BlackContainer container>
        {windowSize === "desktop" ? (
          <>
            <Grid item xs={9}>
              <Video />
              <About t={t} />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                paddingLeft: `${ptToVwConverter(spacing)}vw`,
                maxWidth: `${ptToVwConverter(maxWidth)}vw`,
              }}
            >
              <Upcoming items={upcoming} lang={lang} />
              <News items={news} lang={lang} />
            </Grid>
          </>
        ) : (
          <>
            <Video />
            <About t={t} />
            <Upcoming items={upcoming} lang={lang} />
            <News items={news} lang={lang} />
          </>
        )}
      </BlackContainer>
      <Main
        performances={performances}
        workshops={workshops}
        mediaUrls={instaMediaUrls}
        lang={lang}
      />
    </>
  );
}

export async function getStaticProps() {
  const baseURL = process.env.MICRO_URL;

  const newsURL = baseURL + "news?limit=1000";
  const performanceURL =
    baseURL + "performance?limit=1000&filters=isTop[equals]true";
  const workshopURL =
    baseURL + "workshops?limit=1000&filters=isTop[equals]true";

  const config = {
    headers: {
      "X-API-KEY": process.env.MICRO_API_KEY,
    },
  };

  const newsResponse: NewsResponse = await axios.get(newsURL, config);

  const news = newsResponse.data.contents.sort((a, b) => {
    const recent = new Date(a.publishedAt).getTime();
    const old = new Date(b.publishedAt).getTime();
    return old - recent;
  });

  const upcoming = news
    .filter((content: { isUpcoming: boolean }) => content.isUpcoming)
    .filter((_, i) => i === 0 || i === 1);

  const performanceResponse: PerformanceResponse = await axios.get(
    performanceURL,
    config
  );

  const performances = performanceResponse.data.contents
    .filter((show) => show.isTop)
    .sort((a, b) => {
      const aDate = a.publishedAt as string;
      const bDate = b.publishedAt as string;
      const recent = new Date(aDate).getTime();
      const old = new Date(bDate).getTime();
      return old - recent;
    });

  const wsResponse: WorkshopResponse = await axios.get(workshopURL, config);

  const workshops = wsResponse.data.contents
    .filter((ws) => ws.isTop)
    .sort((a, b) => {
      const aDate = a.publishedAt as string;
      const bDate = b.publishedAt as string;
      const recent = new Date(aDate).getTime();
      const old = new Date(bDate).getTime();
      return old - recent;
    });

  const instaResponse = await axios.get(
    "https://graph.instagram.com/me/media",
    {
      params: {
        fields: "media_url,thumbnail_url,timestamp,permalink",
        access_token: process.env.INSTA_ACCESS_TOKEN,
        size: "t",
      },
    }
  );

  const instaMediaUrls = instaResponse.data.data
    .sort((a: { timestamp: string }, b: { timestamp: string }) => {
      const old = new Date(a.timestamp).getTime();
      const latest = new Date(b.timestamp).getTime();
      return latest - old;
    })
    .map(
      (obj: {
        media_url?: string;
        thumbnail_url?: string;
        timestamp: string;
        permalink: string;
      }) => {
        return {
          link: obj.permalink,
          src: obj.thumbnail_url || obj.media_url || "",
        };
      }
    );

  return {
    props: {
      news,
      upcoming,
      performances,
      workshops,
      instaMediaUrls,
      namespacesRequired: [],
    },
  };
}

export default Top;
