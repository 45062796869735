import { CircularProgress } from "@material-ui/core";
import { ptToVwConverter } from "../../utils";
import useWindowSize from "../../utils/useWindowSize";

export default ({ size }: { size: number }) => {
  const windowSize = useWindowSize();

  return (
    <div
      style={{
        width: "100%",
        height: windowSize === "desktop" ? `${ptToVwConverter(675)}vw` : "55vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#1a1a1a",
      }}
    >
      <CircularProgress color="primary" size={size} />
    </div>
  );
};
