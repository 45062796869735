// import { TwitterTimelineEmbed } from "react-twitter-embed";
// import { ptToVwConverter, mobileVwConverter } from "../../../../utils";
import {
  TopSNSContainer,
  TitleOdl as TitleButton,
  SNSOdl,
  NameOdl,
  SNSContentsWrapper,
} from "../../../style";
import { socialMedia } from "../../../../config";
import useWindowSize from "../../../../utils/useWindowSize";
import Head from "next/head";

export default () => {
  const account = "zero_aokigwp";
  const link = socialMedia[2].link;
  const size = useWindowSize();

  if (!size) return <p></p>;

  return (
    <TopSNSContainer>
      <Head>
        {/* FIXME: Temporary fix for twitter not being embedded properly */}
        <script
          defer
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
      </Head>
      <TitleButton onClick={() => window.open(link, "_blank")}>
        <SNSOdl src="/assets/twitterLogoColor.png" alt="twitter" />
        <NameOdl>Twitter</NameOdl>
      </TitleButton>

      <SNSContentsWrapper>
        {/* FIXME: Temporary fix for twitter not being embedded properly */}
        <a
          className="twitter-timeline"
          href={`https://twitter.com/${account}?ref_src=twsrc%5Etfw`}
        >
          Go to Twitter
        </a>
        {/* <div style={{ display: "none" }}>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName={account}
            options={
              size !== "mobile"
                ? {
                    height: `${ptToVwConverter(670 - 2)}vw`,
                  }
                : {
                    height: `${mobileVwConverter(1000)}vw`,
                    width: "calc(100% - 2px)",
                  }
            }
          />
        </div> */}
      </SNSContentsWrapper>
    </TopSNSContainer>
  );
};
