import PreCSS from "../../../src/utils/preRenderCSS";

import {
  NewsText,
  NewsSmallTitle,
  NewsTitle,
  UpcomingContainer,
  MoreButton,
  LinkButton,
} from "../style";

import { convertDate, ptToVwConverter } from "../../utils";

import Link from "../Link";
import useWindowSize from "../../utils/useWindowSize";

interface Props {
  items: News[];
  lang: string;
}

export default ({ items, lang }: Props) => {
  const size = useWindowSize();

  if (!size) return <p></p>;

  return (
    <UpcomingContainer>
      <PreCSS />
      <NewsTitle>News</NewsTitle>
      {lang ? (
        items.length ? (
          items.map((item, i) => {
            if (i < 3) {
              return (
                <LinkButton
                  key={i}
                  style={{
                    marginBottom: `${ptToVwConverter(16)}vw`,
                    width: "100%",
                    borderBottom:
                      size === "desktop" || i === 2 ? "" : "solid 1px #4d4d4d",
                  }}
                >
                  <Link href="/news/[id]" as={`/news/${item.id}`}>
                    {/* <Link href="/news/[id]" as={`/news/${i}`}> */}
                    <NewsSmallTitle>
                      {lang === "ja"
                        ? item.title
                        : lang === "en"
                        ? item.titleEng
                        : ""}
                    </NewsSmallTitle>
                    <NewsText>
                      {lang === "ja"
                        ? item.subTitle
                        : lang === "en"
                        ? item.subTitleEng
                        : ""}
                    </NewsText>
                    <NewsText>{convertDate(item.publishedAt)}</NewsText>
                  </Link>
                </LinkButton>
              );
            }
            return;
          })
        ) : (
          <div style={{ color: "white" }}>
            {lang === "ja"
              ? "表示する情報がありません。"
              : lang === "en"
              ? "No item found."
              : ""}
          </div>
        )
      ) : (
        <></>
      )}
      {lang && items.length && (
        <Link href="/[page]" as="/news">
          <MoreButton>More</MoreButton>
        </Link>
      )}
    </UpcomingContainer>
  );
};
