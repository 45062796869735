import React from "react";
import { ptToVwConverter, mobileVwConverter } from "../../../../utils";

import PerfectScrollbar from "react-perfect-scrollbar";

import {
  TitleOdl as TitleButton,
  SNSOdl,
  NameOdl,
  TopSNSContainer,
  SNSContentsWrapper,
  InstaImage,
} from "../../../style";
import { socialMedia } from "../../../../config";
import { Button, Grid } from "@material-ui/core";
import useWindowSize from "../../../../utils/useWindowSize";

export default ({
  mediaUrls,
}: {
  mediaUrls: [{ link: string; src: string }];
}) => {
  const link = socialMedia[3].link;
  const size = useWindowSize();

  if (!size) return <p></p>;

  return (
    <TopSNSContainer>
      <TitleButton onClick={() => window.open(link, "_blank")}>
        <SNSOdl src="/assets/instaLogoColor.png" alt="instagram" />
        <NameOdl>Instagram</NameOdl>
      </TitleButton>

      <SNSContentsWrapper
        style={
          size === "desktop"
            ? {}
            : {
                height: `${mobileVwConverter(1059)}vw`,
              }
        }
      >
        <PerfectScrollbar
          style={{
            width: "100%",
          }}
        >
          <Grid container style={{ width: "100%", margin: 0 }} spacing={3}>
            {mediaUrls?.map((resource, i) => (
              <Grid
                item
                xs={6}
                style={{
                  padding: i % 2 === 0 ? "0 2px 4px 0" : "0 0 4px 2px",
                  height:
                    size === "desktop"
                      ? `${ptToVwConverter(176.5)}vw`
                      : `${mobileVwConverter(265)}vw`,
                }}
                key={i}
              >
                {resource?.src && (
                  <InstaImage
                    style={{
                      backgroundImage: `url(${resource.src})`,
                    }}
                    onContextMenu={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    onClick={() => window.open(resource.link, "_blank")}
                    aria-label="instagram image on the left"
                  >
                    {""}
                  </InstaImage>
                )}
              </Grid>
            ))}
          </Grid>
          {/* {mediaUrls?.map((resource, i) => (
            <React.Fragment key={i}>
              {i % 2 === 0 ? (
                <div style={{ display: "flex" }} key={i}>
                  {resource?.src && (
                    <InstaImage
                      style={{
                        backgroundImage: `url(${resource.src})`,
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onClick={() => window.open(resource.link, "_blank")}
                      aria-label="instagram image on the left"
                    />
                    // <Button
                    //   style={{
                    //     width: `${ptToVwConverter(176.5)}vw`,
                    //     height: `${ptToVwConverter(176.5)}vw`,
                    //     marginBottom: `${ptToVwConverter(5)}vw`,
                    //     backgroundImage: `url(${resource.src})`,
                    //     backgroundSize: "cover",
                    //     backgroundRepeat: "no-repeat",
                    //     borderRadius: 0,
                    //   }}
                    //   onContextMenu={(e) => {
                    //     e.preventDefault();
                    //     return false;
                    //   }}
                    //   onClick={() => window.open(resource.link, "_blank")}
                    //   aria-label="instagram image on the left"
                    // />
                  )}
                  {mediaUrls[i + 1]?.src && (
                    <InstaImage
                      style={{
                        marginLeft: `${ptToVwConverter(5)}vw`,
                        backgroundImage: `url(${mediaUrls[i + 1].src})`,
                      }}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                      onClick={() =>
                        window.open(mediaUrls[i + 1].link, "_blank")
                      }
                      aria-label="instagram image on the right"
                    />
                    // <Button
                    //   style={{
                    //     width: `${ptToVwConverter(176.5)}vw`,
                    //     height: `${ptToVwConverter(176.5)}vw`,
                    //     marginBottom: `${ptToVwConverter(5)}vw`,
                    //     marginLeft: `${ptToVwConverter(5)}vw`,
                    //     backgroundImage: `url(${mediaUrls[i + 1].src})`,
                    //     backgroundSize: "cover",
                    //     backgroundRepeat: "no-repeat",
                    //     borderRadius: 0,
                    //   }}
                    //   onContextMenu={(e) => {
                    //     e.preventDefault();
                    //     return false;
                    //   }}
                    //   onClick={() =>
                    //     window.open(mediaUrls[i + 1].link, "_blank")
                    //   }
                    //   aria-label="instagram image on the right"
                    // />
                  )}
                </div>
              ) : (
                <React.Fragment key={i} />
              )}
            </React.Fragment>
          ))} */}
        </PerfectScrollbar>
      </SNSContentsWrapper>
    </TopSNSContainer>
  );
};
