import React from "react";

import Contents from "./Contents";
import { TopMainContainer } from "../../style";

export default ({
  performances,
  workshops,
  lang,
  mediaUrls,
}: {
  performances: PerformanceObject[];
  workshops: Workshop[];
  lang: string;
  mediaUrls: [{ link: string; src: string }];
}) => {
  const sns: string[] = ["Twitter", "Facebook", "Instagram"];

  // TODO: How to sort?
  const selectedPerformances = performances.filter((_, i) => i < 3);
  const selectedWorkshops = workshops.filter((_, i) => i < 3);

  const contents = [
    { title: "performances", posts: selectedPerformances },
    { title: "workshops", posts: selectedWorkshops },
    { title: "our daily life", posts: sns },
  ];

  return (
    <TopMainContainer>
      {contents.map((data) => (
        <Contents
          title={data.title}
          posts={data.posts}
          lang={lang}
          key={data.title}
          mediaUrls={mediaUrls}
        />
      ))}
    </TopMainContainer>
  );
};
