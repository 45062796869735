import { AboutText, AboutContainer, AboutButton } from "../style";

import Link from "../Link";
import { TFunction } from "next-i18next";

// export default ({ about, button }: { about: string; button: string }) => {
//   return (
//     <AboutContainer>
//       <AboutText>{about !== "about" && about}</AboutText>
//       <Link href="/[page]" as="/about">
//         {/* FIXME: how to display zero */}
//         <AboutButton>{button !== "button" && button}</AboutButton>
//       </Link>
//     </AboutContainer>
//   );
// };

export default ({ t }: { t: TFunction }) => {
  return (
    <AboutContainer>
      <AboutText>{t("about") !== "about" ? t("about") : ""}</AboutText>
      <Link href="/[page]" as="/about">
        <AboutButton>{t("button") !== "button" ? t("button") : ""}</AboutButton>
      </Link>
    </AboutContainer>
  );
};
