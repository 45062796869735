import { ptToVwConverter } from "../../../utils";
import React from "react";

import Event from "./Event";
import SNS from "./SNS";
import Link from "../../Link";
import {
  TopContentsRightContainer,
  TopOdlContainer,
  TopContentsButtonContainer,
  TopContentsMain,
  TopContentsMoreButton,
} from "../../style";

export default ({
  title,
  posts,
  lang,
  mediaUrls,
}: {
  title: string;
  posts: (PerformanceObject | Workshop | string)[];
  lang: string;
  mediaUrls: [{ link: string; src: string }];
}) => {
  return (
    <TopContentsRightContainer>
      {title === "our daily life" ? (
        <TopOdlContainer>
          {posts.map((post, i) => {
            const name = post as string;
            return (
              <React.Fragment key={name}>
                <SNS name={name} mediaUrls={mediaUrls} />
                {i !== posts.length - 1 && (
                  <div style={{ width: `${ptToVwConverter(30)}vw` }} />
                )}
              </React.Fragment>
            );
          })}
        </TopOdlContainer>
      ) : (
        <>
          <TopContentsMain>
            {posts.map((post, i) => {
              const data = post as PerformanceObject | Workshop;
              return (
                <React.Fragment key={data.title}>
                  <Event data={data} lang={lang} type={title} />
                  {i !== posts.length - 1 && (
                    <div style={{ width: `${ptToVwConverter(30)}vw` }} />
                  )}
                </React.Fragment>
              );
            })}
          </TopContentsMain>
          <TopContentsButtonContainer>
            <Link href="/[page]" as={`/${title}`}>
              <TopContentsMoreButton>More</TopContentsMoreButton>
            </Link>
          </TopContentsButtonContainer>
        </>
      )}
    </TopContentsRightContainer>
  );
};
