import RightContainer from "./RightContainer";
import {
  TopContentsContainer as Container,
  DecorationLine,
  TopContentsTitleContainer as TitleContainer,
  TopContentsTitle as Title,
} from "../../style";
import useWindowSize from "../../../utils/useWindowSize";
// import { mobileVwConverter } from "../../../utils";

export default ({
  title,
  posts,
  lang,
  mediaUrls,
}: {
  title: string;
  posts: (PerformanceObject | Workshop | string)[];
  lang: string;
  mediaUrls: [{ link: string; src: string }];
}) => {
  const size = useWindowSize();

  if (!size) return <p></p>;

  return (
    <Container key={title}>
      <>
        {size === "desktop" ? (
          <>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
            <DecorationLine />
          </>
        ) : (
          <>
            <div
              style={{
                fontSize: "18px",
                letterSpacing: "0.1em",
                textTransform: "uppercase",
                fontWeight: 400,
              }}
            >
              {title}
            </div>
            <DecorationLine />
          </>
        )}
        <RightContainer
          title={title}
          posts={posts}
          mediaUrls={mediaUrls}
          lang={lang}
        />
      </>
    </Container>
  );
};
