import {
  TopEventContainer,
  TopEventImage,
  TopEventImageAlt,
  TopEventTitle,
  TopEventLocation,
  TopEventSponsor,
  EventButton,
} from "../../style";
import Link from "../../Link";
import { Button } from "@material-ui/core";

export default ({
  data,
  lang,
  type,
}: {
  data: PerformanceObject | Workshop;
  lang: string;
  type: string;
}) => {
  return (
    <TopEventContainer>
      <EventButton aria-label={type}>
        {/* <Button style={{ padding: 0, textAlign: "unset" }} aria-label={type}> */}
        <Link href="/[page]/[id]" as={`/${type}/${data.id}`}>
          {data.imgMain?.url ? (
            <TopEventImage
              src={`${data.imgMain?.url}?h=240&w=360`}
              alt={data.title}
              onContextMenu={(e: React.MouseEvent) => {
                e.preventDefault();
                return false;
              }}
            />
          ) : (
            <TopEventImageAlt />
          )}

          <TopEventTitle>
            {lang === "ja" ? data.title : lang === "en" ? data.titleEng : ""}
          </TopEventTitle>

          {type === "performances" ? (
            <>
              <TopEventLocation>
                {lang === "ja"
                  ? data.location
                  : lang === "en"
                  ? data.locationEng
                  : ""}
              </TopEventLocation>

              {data.sponsor && (
                <TopEventSponsor>
                  {lang === "ja"
                    ? `${data.sponsor}`
                    : lang === "en"
                    ? `${data.sponsorEng}`
                    : ""}
                </TopEventSponsor>
              )}
            </>
          ) : (
            <TopEventLocation style={{ textTransform: "none" }}>
              {lang === "ja"
                ? data.topIntro
                : lang === "en"
                ? data.topIntroEng
                : ""}
            </TopEventLocation>
          )}
        </Link>
        {/* </Button> */}
      </EventButton>
    </TopEventContainer>
  );
};
