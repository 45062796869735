import { useEffect } from "react";
import { useRouter } from "next/router";
import { options } from "../config";
import useWindowSize from "./useWindowSize";

export default () => {
  const router = useRouter();
  const windowSize = useWindowSize();

  useEffect(() => {
    if (windowSize === "desktop") {
      const path = router.pathname;

      const tabs = document.getElementsByClassName("menuTab");
      if (path === "/" || path === "/contact") {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < tabs.length; i++) {
          const tab = tabs[i] as HTMLElement;
          if (tab && tab.style) {
            tab.style.borderBottom = "none";

            if (tab.children[1]?.tagName === "DIV") {
              tab.removeChild(tab.children[1]!);
            }
          }
        }
      } else {
        let name = path.slice(1).toLowerCase();
        if (name.includes("_")) {
          name = name.split("_").join(" ");
        }
        const i = options.indexOf(name);
        const tab = tabs[i] as HTMLElement;

        if (tab && tab.style) {
          const copy = document.createElement("div");
          copy.style.position = "absolute";
          copy.style.width = "20px";
          copy.style.height = `30px`;
          copy.style.borderBottom = "solid 1px #fff";
          tab.appendChild(copy);

          tab.style.position = "relative";
        }
      }
    }
  }, [router.pathname]);

  return <></>;
};
