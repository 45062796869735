import {
  NewsText,
  NewsSmallTitle,
  NewsTitle,
  NewsPhoto,
  NewsContainer,
  LinkButton,
} from "../style";

import { convertDate, mobileVwConverter } from "../../utils";
import Link from "../Link";
import useWindowSize from "../../utils/useWindowSize";
import { Grid } from "@material-ui/core";

interface Props {
  items: News[];
  lang: string;
}

// type Data = Omit<News, "body"> & {
//   body: JSX.Element | JSX.Element[];
//   text: string;
// };

export default ({ items, lang }: Props) => {
  const size = useWindowSize();
  // const [lang, setLang] = useState(i18n.language);

  // useEffect(() => {
  //   setLang(i18n.language);
  // }, [i18n.language]);

  // TODO: use this system on the real page
  // const [data, setData] = useState([]) as [
  //   Data[],
  //   Dispatch<SetStateAction<Data[]>>
  // ];

  // useEffect(() => {
  //   if (items.length) {
  //     setData(
  //       items.map((item) => ({
  //         ...item,
  //         body: parse(item.body),
  //         text: "sample",
  //       }))
  //     );
  //   }
  // }, [items]);

  if (!size) return <p></p>;

  return (
    <NewsContainer>
      <NewsTitle>Upcoming</NewsTitle>
      {lang ? (
        items.length ? (
          <Grid
            container
            style={size === "desktop" ? {} : { width: "100%", margin: 0 }}
            spacing={3}
          >
            {items.map((item, i) => (
              <Grid
                item
                xs={size === "desktop" ? 12 : 6}
                style={
                  size === "desktop"
                    ? {}
                    : i % 2 === 0
                    ? {
                        paddingLeft: 0,
                      }
                    : {
                        paddingRight: 0,
                      }
                }
              >
                <Link href="/news/[id]" as={`/news/${item.id}`}>
                  {/* <Link href="/news/[id]" as={`/news/${i}`}> */}
                  <LinkButton key={i}>
                    {item.imgMain && (
                      <NewsPhoto
                        src={`${item.imgMain?.url}?h=180&w=270`}
                        alt={item.title}
                        onContextMenu={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                      />
                    )}
                    <NewsSmallTitle style={{ width: "100%" }}>
                      {lang === "ja"
                        ? item.title
                        : lang === "en"
                        ? item.titleEng
                        : ""}
                    </NewsSmallTitle>
                    <NewsText style={{ width: "100%" }}>
                      {lang === "ja"
                        ? item.subTitle
                        : lang === "en"
                        ? item.subTitleEng
                        : ""}
                    </NewsText>
                    <NewsText>
                      {lang === "ja"
                        ? item.eventDate
                        : lang === "en"
                        ? item.eventDateEng
                        : ""}
                    </NewsText>
                    <br />
                  </LinkButton>
                </Link>
              </Grid>
            ))}
          </Grid>
        ) : (
          <div style={{ color: "white" }}>
            {lang === "ja"
              ? "表示する情報がありません。"
              : lang === "en"
              ? "No item found."
              : ""}
          </div>
        )
      ) : (
        <></>
      )}
    </NewsContainer>
  );
};
