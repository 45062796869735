import Twitter from "./Twitter";
import Facebook from "./Facebook";
import Instagram from "./Instagram";

export default ({
  name,
  mediaUrls,
}: {
  name: string;
  mediaUrls: [{ link: string; src: string }];
}) => {
  if (name === "Twitter") {
    return <Twitter />;
  } else if (name === "Facebook") {
    return <Facebook />;
  } else if (name === "Instagram") {
    return <Instagram mediaUrls={mediaUrls} />;
  } else {
    return <></>;
  }
};
