import { useState, useEffect, SyntheticEvent } from "react";
import Carousel, { CarouselInternalState } from "react-multi-carousel";
import Loader from "./Loader";
import { Button } from "@material-ui/core";
import { ptToVwConverter } from "../../utils";
import useWindowSize from "../../utils/useWindowSize";

import Player from "@vimeo/player";

interface State {
  currentSlide: number;
}

function ButtonGroup({
  next,
  previous,
  goToSlide,
  ...rest
}: {
  next?: any;
  previous?: any;
  goToSlide?: any;
  carouselState?: State;
}) {
  const { carouselState } = rest;
  if (!carouselState) return <></>;

  const { currentSlide } = carouselState;

  return (
    <div
      className="carousel-button-group"
      style={{ margin: `${ptToVwConverter(16 * 0.8)}vw 0`, float: "right" }}
    >
      {[2, 3, 4].map((i) => (
        <Button
          className={`dot-${i}`}
          style={{
            color: currentSlide === i ? "white" : "gray",
            // fontSize: `${ptToVwConverter(7)}vw`,
            fontSize: `7px`,
            minWidth: `${ptToVwConverter(7)}vw`,
          }}
          onClick={() => goToSlide(i)}
          key={i}
          aria-label="white dot to switch videos"
        >
          ●
        </Button>
      ))}
    </div>
  );
}

const setDotColor = (_: number, state: CarouselInternalState) => {
  const className = `dot-${state.currentSlide}`;
  const dots = document.getElementsByClassName(className);
  const dot = dots[0] as HTMLElement;
  if (dot && dot.style) {
    dot.style.color = "white";
  }
};

const responsive = {
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024,
    },
    items: 1,
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0,
    },
    items: 1,
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464,
    },
    items: 1,
  },
};

export default () => {
  const isWindowClient = typeof window === "object";

  const [resized, setResized] = useState<{ [key: string]: boolean }>({});
  const size = useWindowSize();

  const urls =
    size === "desktop"
      ? ["429098971", "429528589", "429537097"]
      : ["429098971"];

  const [init, setInit] = useState(false);

  useEffect(() => {
    if (isWindowClient && !init) {
      setReady(false);

      urls.forEach((url) => {
        setResized((prev) => ({ ...prev, [url]: false }));
      });

      setInit(true);
    }
  }, [init]);

  const onLoaded = (e: SyntheticEvent<HTMLIFrameElement, Event>) => {
    const key = e.currentTarget.className;

    setResized((prev) => ({ ...prev, [key]: true }));
  };

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isWindowClient && size === "mobile") {
      const video = document.querySelector("iframe") as HTMLIFrameElement;
      if (video && !ready) {
        video.style.height = "55vw";

        const player = new Player(video);
        player.on("progress", () => {
          setReady(true);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isWindowClient && size !== "mobile") {
      let isReady = true;
      if (Object.entries(resized).length) {
        // tslint:disable-next-line: forin
        for (const key in resized) {
          if (!resized[key]) {
            isReady = false;
          }
        }
      } else {
        isReady = false;
      }

      if (isReady) {
        urls.forEach((url) => {
          const videos = document.getElementsByClassName(url);
          if (videos.length) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < videos.length; i++) {
              const video = videos[i] as HTMLElement;
              if (video && video.style) {
                video.style.height =
                  size === "desktop" ? `${ptToVwConverter(675)}vw` : "55vw";
              }
            }
          }
        });
      }

      setReady(isReady);
    }
  }, [resized]);

  // console.log("Init: ", init);
  // console.log("Resized: ", resized);
  // console.log("Ready: ", ready);

  if (!size) return <p></p>;

  return (
    <>
      {ready || <Loader size={size === "desktop" ? 100 : 50} />}
      <div
        style={{
          visibility: ready ? "visible" : "hidden",
          height: ready ? "auto" : 0,
          width: ready && size !== "desktop" ? "100%" : "unset",
        }}
      >
        {size === "desktop" ? (
          <Carousel
            ssr
            additionalTransfrom={0}
            autoPlay
            autoPlaySpeed={8000}
            customTransition="all .3s ease-in"
            centerMode={false}
            infinite
            responsive={responsive}
            slidesToSlide={1}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            afterChange={setDotColor}
            arrows={false}
          >
            {urls.map((url) => (
              <iframe
                key={url}
                className={url}
                src={`https://player.vimeo.com/video/${url}?autoplay=1&loop=1&autopause=0&muted=1&background=1`}
                width="100%"
                height={`${ptToVwConverter(675)}vw`}
                frameBorder="0"
                allow="autoplay"
                onLoad={onLoaded}
                title="carousel video"
              />
            ))}
          </Carousel>
        ) : (
          <>
            {urls.map((url) => (
              <iframe
                key={url}
                className={url}
                src={`https://player.vimeo.com/video/${url}?autoplay=1&loop=1&autopause=0&muted=1&background=1`}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay"
                onLoad={onLoaded}
                title="carousel video"
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};
